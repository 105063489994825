
import React from 'react';

import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({component: C, appProps, ...rest}) => {
	return( <Route  {...rest} 
					render= {props => (appProps.user === '') ? <Redirect to="/login" />
				                                             : <C {...props} {...appProps} />}
    />
  );
}

export default PrivateRoute;