
import React, { useState, useEffect, useReducer } from 'react';
import axios from 'axios';

//bootstrap
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

import { VictoryChart, VictoryAxis, VictoryCandlestick, VictoryLine,
 VictoryBar, Selection, VictoryScatter, VictoryArea, VictoryLabel} from 'victory'; 

const MaingraphAdj = (props) => {
    const [data, setdata] = useState([]);	
	useEffect(() => {
		(async () => {
			const result = await axios.get(`/maingraphadj`, {withCredentials: true});
			setdata(result.data)
		})();
	  }, []);	
	//console.log(data)
	return (<Container><Table responsive size="sm" ><tbody>
				<tr> 
				  <td className="chart">
					<VictoryChart scale={{ x: "time"} } 								  
					>
						<VictoryAxis
									dependentAxis={true}
									style={{
									  grid: { stroke: "gainsboro" },
                                      tickLabels: {fontSize: 8, padding: 5}
									}}
									/>								  
						<VictoryAxis
									style={{
									  grid: { stroke: "gainsboro" },
                                      tickLabels: {fontSize: 10, padding: 0}
									}}
									/>
						<VictoryLine data={data}  
						             x="ts" 
									 y="growth" 
									 standalone={false} 
									 style={{data: { stroke: "blue"}}} 
						/>
					</VictoryChart>
				  </td>
				</tr>
			  </tbody>
			</Table> </Container> 
	)
}

export default MaingraphAdj;