
import React, { useState, useEffect, useReducer } from 'react';
//import ReactDOM from 'react-dom';
//socketio
//import {socket, API_URL} from './websocket'
import axios from 'axios';

//import Navibar from './Navibar'

//bootstrap
import Container from 'react-bootstrap/Container'
//import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
//import Card from 'react-bootstrap/Card'
//import Navbar from 'react-bootstrap/Navbar'
//import Nav from 'react-bootstrap/Nav'
//import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
//import FormControl from 'react-bootstrap/FormControl'
import Table from 'react-bootstrap/Table'
//import Dropdown from 'react-bootstrap/Dropdown'*/
import Button from 'react-bootstrap/Button'

import { VictoryChart, VictoryAxis, VictoryCandlestick, VictoryLine,
 VictoryBar, Selection, VictoryScatter, VictoryArea, VictoryLabel} from 'victory'; 

import yahoologo from './yahoo.jpg'
import avlogo from './AV_400x400.jpg'

// initialize the list of colors for the char lines
import variables from './Sass/App.scss'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTimesCircle,faSave } from '@fortawesome/free-solid-svg-icons'

var colorlist = []
for (var key in variables) {
colorlist.push(variables[key])
}

function MillisToShortDate (millis) {
	return ( ("00" + (new Date(Number.parseInt(millis,10))).getDate()).slice(-2) + "/" + ("00" + ((new Date(Number.parseInt(millis,10))).getMonth() + 1)).slice(-2) + "/" + ((new Date(Number.parseInt(millis,10))).getFullYear().toString()).slice(-2))
}
	
function EUDateStringToMillis (datestring) {
	var firstsep = datestring.indexOf("/")
	var secondsep = datestring.indexOf("/", datestring.indexOf("/") + 1 )
	var day = datestring.substring(0,firstsep)
	var month = datestring.substring(firstsep + 1,secondsep)
	var year = datestring.substring(secondsep + 1,1000)
	if (year.length === 2) { year = "20" + year}
	return ( (new Date(year + "-" + month + "-" + day)).getTime())
}

function compare_anset(a, b) {
  const A = a.x;
  const B = b.x;

  let comparison = 0;
  if (A > B) {
	comparison = 1;
  } else if (A < B) {
	comparison = -1;
  }
  return comparison;
}


const Index = (props) => {
    const [data, setdata] = useState([]);

	var date = new Date();
	var timestamp = date.getTime();
	var horz = timestamp - props.range*365.25*60*60*24*1000;
	const [valueInfo, setvalueInfo] = useState([props.ind.field10]);
	
	 useEffect(() => {  //this useEffect first to fetch the initial state asynchronously
		 	async function InitSources(initstate) {
			  try {
				  var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
				  //console.log(timeStampInMs, Date.now());
				  const result = await axios.get(`/allsources?symbol=${props.ind.symbol}`, {withCredentials: true});  // this await is straightforward
				  //console.log(result)
				  result.data.map( async (series, index) => {  //make this one async as well as we are fetching 
					  var result2 = {data:[]}
					  if (series.soft_flag == 1) {
						var timeStampInMs3 = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
				        result2 = await axios.get(`/allindexdata?symbol=${series.symbolq}&source=${series.source}&function=${series.function}&horz=${horz}`, {withCredentials: true});
						var timeStampInMs4 = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
					    //console.log(timeStampInMs4 - timeStampInMs3, Date.now(), 'allindexdata');
					  }
					  //console.log(result2)
					  var sourcelogo
					  switch(series.source) {
						  case 'ALPHAVANTAGE':
							sourcelogo = avlogo
							break;
						  case 'YAHOOQUERY':
							sourcelogo = yahoologo
							break;
						  default:
							sourcelogo = series.source
						}
					  var intvl
					  switch(series.function) {
						  case 'TIME_SERIES_DAILY':
							intvl = '1d'
							break;
						  case '1d':
							intvl = '1d'
							break;
						  default:
							intvl = series.function
						}
					  setdata(initst => [...initst, {source:sourcelogo, function:intvl, color:colorlist[index], series:result2.data, enabled:series.soft_flag, sid: series.id, osrc:series.source, oft:series.function, symbol:series.symbolq}])
			  })
			  
			  var timeStampInMs2 = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
				  //console.log(timeStampInMs2 - timeStampInMs, Date.now(), 'x');
			  
			  } catch (err) {
				  console.log(err)
				}
			}
			InitSources([])
		  }, [props.ind.soft_flag, props.ind.symbol]);
	
	function DisableSeries(sid) {
	  try {
		  axios.put(`/disableseries/${sid}`, {withCredentials: true});
	  } catch (err) {
		  console.log(err)
		}
	}
	
	function EnableSeries(sid) {
	  try {
		  axios.put(`/enableseries/${sid}`, {withCredentials: true});
	  } catch (err) {
		  console.log(err)
		}
	}
		
	function compare(a, b) {
	  const A = a.function;
	  const B = b.function;

	  let comparison = 0;
	  if (A > B) {
		comparison = 1;
	  } else if (A < B) {
		comparison = -1;
	  }
	  return comparison;
	}
	var prevp;
	
	const adddelta = annot => {
			var delta = 0
			if (annot.atype === 'Label' && annot.deleted != 1) {
				if (prevp != -1) {
					delta = Math.round((annot.y/prevp*100-100) * 10) /10	
				}
				prevp = annot.y
			}
			return {...annot, delta: delta}
		 }
	
	const srsReducer = (state, action) => {  //the reducer which will enable interacting with the leafs of the tree at will
	  prevp = -1;
	  switch (action.type) {
		case 'INIT': // the INIT action is just to move the data into our complex datastructure of the useReducer hook
			return {srs:data.sort(compare),anset:props.ind.atset.sort(compare_anset).map(adddelta)}
		case 'ENABLE': 
		  return {...state, srs:state.srs.map(srs => {
			if (srs.sid == action.key) {
			  EnableSeries(srs.sid)	
			  return { ...srs, series:extrafetch.data,enabled:true };
			} else {
			  return srs;
			}
		  })};
		
		case 'DISABLE':
		 return {...state, srs:state.srs.map(srs => {
			if (srs.sid == action.key) {
			  DisableSeries(srs.sid)
			  return { ...srs, series:[],enabled:false };
			} else {
			  return srs;
			}
		 })};

		case 'ADD':	
			return state;

		case 'INS_ANN':
		 return ({srs:state.srs, anset:state.anset.concat(action.annot).sort(compare_anset).map(adddelta)})
		
		case 'UPD_ANN':
		 return {...state, anset:state.anset.map(annot => {
			if (annot.id == action.annot.id) {
			  return  action.annot ;
			} else {
			  return annot;
			}
		 }).sort(compare_anset).map(adddelta)};	
		  
		default:
		  return state;
	  }
	};
	
	
	const [extrafetch, setextrafetch] = useState({sid:0,data:[]});
	
	useEffect(() => { 
		dispatch({type:'ENABLE', key:extrafetch.sid})
	}, [extrafetch]);
	
	
	function handleChangeCB
	  (event) {
			if (event.target.checked) {
				async function ExtraFetch(sid,symbol,source,fct) {
			  try {
					  const result2 = await axios.get(`/allindexdata?symbol=${symbol}&source=${source}&function=${fct}&horz=${horz}`, {withCredentials: true});
					  setextrafetch({sid:sid,data:result2.data})
				  } catch (err) {
					  console.log(err)
					}
			}
			ExtraFetch(event.target.dataset.key, event.target.dataset.symbol, event.target.dataset.src, event.target.dataset.fct)
			}
		     else {dispatch({type:'DISABLE', key:event.target.dataset.key})}
	  }

	const [srs, dispatch] = useReducer(srsReducer, {srs:[],anset:[]});

    useEffect(() => {  //this useEffect
			dispatch({type: 'INIT'})
		  }, [data]);

	//const [sorter, setsorter] = useState(props.ind.sorter);
    var maxsort
	
	useEffect(() => {  //this useEffect
			maxsort = Math.max.apply(Math, srs.anset.filter(x => {return x['sortfield']> 0}).map(function(o) { return o.growth; }))
			//maxsort = Math.min.apply(Math, srs.anset.filter(x => {return x['sortfield']< 0}).map(function(o) { return o.growth * -1; }))
			//console.log(maxsort)
			//console.log(props.ind.sorter)
			if (maxsort != props.ind.sorter) {
			//console.log(props.ind.id)
			props.dispatchind({type: 'UPD_IND', key:props.ind.id, sorter:maxsort})
			}
		  }, [srs]);	
	
	function handleSubmitvalueInfo
	(event) {
	async function persistData() {
		//console.log(valueInfo)
		//console.log('in handleSubmitvalueInfo');
       try {
	  const result = await axios.put(`/setinfo/${props.ind.id}`, 
										JSON.stringify({"field10": valueInfo}),
									  {withCredentials: true,
									   headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
										}
									  }
						);
	   } catch (err) {
		   console.log(err)
	   }
    }  
	persistData()
	event.preventDefault();
  }
  
	function handleChangevalueInfo
  (event) { 
	setvalueInfo(event.target.value);
  }

	//console.log(MillisToShortDate(1601966139000))

	//console.log(EUDateStringToMillis('06/10/20') ) 
	
	const [minmaxp, setminmaxp] = useState({minp: 0, maxp:100});
	var minp, maxp, minpn, maxpn
	const [lastxy, setlastxy] = useState({lastx: (new Date()).getTime(), lasty:""});
	useEffect(() => { 
		minp = 100000
		maxp = 0
		srs.srs.forEach((series, index) => {
			if (series.function != 'div') {
				if (series.function.substring(0,2) === '3m') {
					maxpn = Math.max.apply(Math, series.series.filter(x => {return x['tsm']>(new Date()).getTime()- props.range*60*60*24*365.25*1000}).map(function(o) { return o.high; }))
					minpn = Math.min.apply(Math, series.series.filter(x => {return x['tsm']>(new Date()).getTime()- props.range*60*60*24*365.25*1000}).map(function(o) { return o.low; }))
				}
				if (series.function.substring(0,2) === '1d') {
					maxpn = Math.max.apply(Math, series.series.filter(x => {return x['tsm']>(new Date()).getTime()- props.range*60*60*24*365.25*1000}).map(function(o) { return o.close; }))
					minpn = Math.min.apply(Math, series.series.filter(x => {return x['tsm']>(new Date()).getTime()- props.range*60*60*24*365.25*1000}).map(function(o) { return o.close; }))
				}	
			if (minpn < minp) {minp = minpn}
			if (maxpn > maxp) {maxp = maxpn}
			}
		})
		if (props.ind.advicesum.filter(x => {return x['ts']>(new Date()).getTime()- props.range*60*60*24*365.25*1000}).filter(x => x['koersdoel'] > 0).length > 0) {
			maxpn = Math.max.apply(Math, props.ind.advicesum.filter(x => {return x['ts']>(new Date()).getTime()- props.range*60*60*24*365.25*1000}).filter(x => x['koersdoel'] > 0).map(function(o) { return o.koersdoel; }))
			minpn = Math.min.apply(Math, props.ind.advicesum.filter(x => {return x['ts']>(new Date()).getTime()- props.range*60*60*24*365.25*1000}).filter(x => x['koersdoel'] > 0).map(function(o) { return o.koersdoel; }))
			if (minpn < minp) {minp = minpn}
			if (maxpn > maxp) {maxp = maxpn}
		}
		maxp = (1.03*maxp).toFixed(2)
		minp = (0.97*minp).toFixed(2)
		if (minp < 0) {minp = 0}
		setminmaxp({minp, maxp}) 
	}, [srs, props.range]);
	//console.log(props.ind, minmaxp)
	const main_chart_height = 150
	const main_chart_width = 450
	const main_chart_pad_top = 10
	const main_chart_pad_left = 20
	const [ClickedOpen, setClickedOpen] = useState(false);
	const [SettingsClassName, setSettingsClassName] = useState("annotsettingcol");
	//const [rerender, setrerender] = useState(true);   <Button className="overlaybtn2" variant="light" size="sm" onClick={setrerender(true)}><FontAwesomeIcon icon={faTimesCircle} /></Button>
	var maxadv = Math.max(...props.ind.advicesum.map((d) => Math.max(d.kopen, d.verkopen)))
	if (!isFinite(maxadv)) {maxadv = 1}
	//console.log(maxadv)
	return (<Container><Table responsive size="sm" ><tbody>
				<tr>
				  <td>{props.ind.symbol}</td>
				  <td>{props.ind.category}</td>
				  <td>{props.ind.indx}</td>
				  <td>{props.ind.class}</td>
				  <td>{props.ind.geography}</td>
				  <td>{props.ind.kw}</td>
				</tr> 

				<tr>
				  <td>{new Date(props.ind.last_price_date).toLocaleDateString("nl-BE")}</td>
				  <td>{Math.round(props.ind.last_price*100)/100}</td>
				  <td>{props.ind.value}</td>
				  <td>{props.ind.avg_cost}</td>
				  <td>{props.ind.avg_delta}</td>
				  <td></td>
				</tr> 
				<tr> 
				  <td colSpan="6" className="chart">
					<VictoryChart scale={{ x: "time"} } 
								  height={main_chart_height} 
								  width={main_chart_width} 
								  domain= {{x: [horz, timestamp], y: [minmaxp.minp, minmaxp.maxp]}}
								  padding={{top: main_chart_pad_top, bottom: 0, left: main_chart_pad_left, right: 0}} 
								  events={[{
										childName: 'all',
										target: 'parent',
										eventHandlers: {
											onClick: (evt) => {
												const {x, y} = Selection.getSVGEventCoordinates(evt);
												//console.log(x,y)
												setlastxy({lastx: (horz + ((x-main_chart_pad_left)/(main_chart_width-main_chart_pad_left))*(timestamp-horz)).toFixed(0), lasty:Number(minmaxp.minp) +  (((main_chart_height-main_chart_pad_top) - y + main_chart_pad_top)/(main_chart_height-main_chart_pad_top))*(minmaxp.maxp - minmaxp.minp)})
											}
										}
								  }]}
					>
						<VictoryAxis
									dependentAxis={true}
									style={{
									  grid: { stroke: "gainsboro" },
                                      tickLabels: {fontSize: 8, padding: 5}
									}}
									/>								  
						<VictoryAxis
									style={{
									  grid: { stroke: "gainsboro" },
                                      tickLabels: {fontSize: 10, padding: 0}
									}}
									/>
						{srs.srs.map((series, index) => { if (series.function === '1d' || series.function === '1dadj') {
							return(
								<VictoryLine data={series.series}  x="ts" y="close" key={index} standalone={false} style={{data: { stroke: series.color}}} />)
															}
														  else if (series.function === '3m' || series.function === '3madj') {
							return(
								<VictoryCandlestick data={series.series}  x="tsm" key={index} standalone={false} style={{data: { stroke: series.color, opacity:0.5}}}  candleWidth={(main_chart_width-main_chart_pad_left)/(props.range*12)*3} />)			
															}
														})
						}
						{srs.anset.map((an, index) => {if (!an.deleted) {
							if (an.atype === "Line"){ 
								return(
			   								<VictoryLine y={(x) => (1 + parseFloat(an.growth)/100)**(x.x/(365.25*24*60*60*1000)) + parseFloat(an.y) - (1 + parseFloat(an.growth)/100)**(parseFloat(an.x)/(365.25*24*60*60*1000))} key={index+1000} standalone={false} style={{data: { stroke: an.color}}} />)
							} else if (an.atype === "Label") { 
								return(
			   								<VictoryScatter data={[{x:an.x, y:an.y}]}  key={index} standalone={false} style={{data: { fill: an.color}}} labels={[an.text]} />)
								}}
								})
						}
						{
						<VictoryScatter data={[{x:lastxy.lastx - 0, y:lastxy.lasty}]} standalone={false} style={{data: { fill: "grey"}}} />
						}
						<VictoryLine data={props.ind.advicesum}  x="ts" y="koersdoel" standalone={false} style={{data: { stroke: "#17a2b8"}}} />
						
					</VictoryChart>
					<VictoryChart scale={{ x: "time"}}   
								  height={50}  
								  domain= {{x: [horz, timestamp]}}
								  padding={{top: 0, bottom: 10, left: 20, right: 0}}  >
						<VictoryAxis
									dependentAxis={true}
									tickCount={4}
									style={{
									  grid: { stroke: "gainsboro" },
                                      tickLabels: {fontSize: 8, padding: 5}
									}}
									/>								  
						<VictoryAxis
									style={{
									  grid: { stroke: "gainsboro" },
                                      tickLabels: {fontSize: 10, padding: 0}
									}}
									/>
										<VictoryArea
											data={props.ind.advicesum} 
											x="ts"  
											y="kopen"
											style={{ data: { fill: "#28a745" } }}
										/>	
										<VictoryArea
											data={props.ind.advicesum} 
											x="ts"  
											y="verkopen"
											style={{ data: { fill: "#dc3545" } }}
										/>	
										<VictoryScatter
											symbol="star"
											size={0.1}
											data={props.ind.advicesum.map((s) => ({x:s.ts, y:s.rating/5*maxadv}))} 
										/>
																	
		
													
						{srs.srs.map((series, index) => {
							var maxdiv = Math.max(...series.series.map((d) => d.dividends_pct))  ;
							if (series.function === 'div') {
							return(
								<VictoryBar data={series.series.map((s) => ({x:s.ts, y:s.dividends_pct/maxdiv*.8*maxadv}))}  
											labels={series.series.map((s) => Math.round(s.dividends_pct*1000)/10)}
											labelComponent={
												<VictoryLabel verticalAnchor="start"/>
											  }
											key={index} 
											standalone={false} 
											barWidth={2} 
											style={{data: { stroke: series.color, 
											                fill: series.color}}} />)
						}})
						}

						
					</VictoryChart>
					<AnnotSettingsBtn data={srs.anset} dispatch={dispatch} ind_id={props.ind.id} lastxy={lastxy} ClickedOpen={ClickedOpen} setClickedOpen={setClickedOpen} setSettingsClassName={setSettingsClassName} SettingsClassName={SettingsClassName} />
				  </td>
				</tr>
				
				<AnnotSettings data={srs.anset} dispatch={dispatch} ind_id={props.ind.id} lastxy={lastxy} ClickedOpen={ClickedOpen} srs={srs.srs} handleChangeCB={handleChangeCB} SettingsClassName={SettingsClassName}
				handleChangevalueInfo={handleChangevalueInfo}   handleSubmitvalueInfo={handleSubmitvalueInfo}    valueInfo={valueInfo}
				/>
			  </tbody>
			</Table> </Container> 
	)
}

const AnnotSettingsBtn = (props) => {
	
    function ToggleForm
	  (event) {// props.setClickedOpen(!props.ClickedOpen)
				if (props.SettingsClassName==="annotsettingcol") {
					props.setSettingsClassName("annotsettingexp")
				} else{
					props.setSettingsClassName("annotsettingcol")
				}
	  }

	if (props.ClickedOpen) {return(
			 <Button className="overlaybtn2" variant="light" size="sm" onClick={ToggleForm}>
			<FontAwesomeIcon icon={faTimesCircle} /></Button>)
							 } 
			 return(   
			<Button className="overlaybtn2" variant="light" size="sm" onClick={ToggleForm} ><FontAwesomeIcon
                  icon={faCog} 
                /></Button>
					)
}


const AnnotSettings = (props) => {
	
	return(
		<React.Fragment>
			<tr className={props.SettingsClassName} >
				<td  className={props.SettingsClassName}><div>
					<Table responsive size="sm">
						<tbody>
							{props.srs.map((series, index) => {
								return (<Legend key={index} series={series} handleChangeCB={props.handleChangeCB}/>)
							})}
						</tbody>
					</Table></div>
				</td>
				<td className={props.SettingsClassName} colSpan="6"><div>
					<Table  responsive size="sm" bordered>
						<tbody>
							{props.data.map( (annot, index) => {
								if (!annot.deleted) {
									return(
										<Annot key={index} annot={annot} dispatch={props.dispatch} status="db" lastxy={props.lastxy}/>
							)}})}
							<Annot key={props.data.length} annot={{ind_id:props.ind_id, x:"", y:"",atype:"Label",text:"", growth:"", sortfield:"", color:"blue"}}
								   dispatch={props.dispatch} status="new" lastxy={props.lastxy} />
						</tbody>
					</Table></div>
				</td>
			</tr>
			<tr className={props.SettingsClassName}>
				<td className={props.SettingsClassName} colSpan="5"><div>
					<Form onSubmit={props.handleSubmitvalueInfo}>
						<Form.Control 	as="textarea"
										value={props.valueInfo} 
										onChange={props.handleChangevalueInfo}
							/>
						<Button variant="light" size="sm" type="submit" className="annot" >
						<FontAwesomeIcon
							  icon={faSave} 
							/>
					  </Button>
					</Form></div>
				</td>
			</tr>
		</React.Fragment>)
}

const Annot = (props) => {
	const [annotloc, setannotloc] = useState({ind_id:props.annot.ind_id, x:MillisToShortDate(props.annot.x), y:props.annot.y, atype:props.annot.atype, text:props.annot.text, id:props.annot.id, growth:(props.annot.growth == null ? "" : props.annot.growth), sortfield:(props.annot.sortfield == null ? "" : props.annot.sortfield), color:props.annot.color, delta:props.annot.delta});
	const [invaliddate, setinvaliddate] = useState(false);
    const [isLine, setisLine] = useState(props.annot.atype === 'Line' ? true : false);
	
	function handleChangeal
	  ( event) { const { id, value } = event.target;
					if (id === "atype" && value === "Line") {setisLine(true)}
					if (id === "atype" && value === "Label") {setisLine(false)}
					setannotloc(prevState => ({
							...prevState,
							[id]: value
						 })
						 )
	  }
	  
	useEffect(() => {  //this useEffect
		if (props.status === "new") {
			setannotloc(prevState => ({
							...prevState,
							x: MillisToShortDate(props.lastxy.lastx),
							y: props.lastxy.lasty,
						 }))
		}
		  }, [props.lastxy]);

	useEffect(() => {  //this useEffect
		//
		if (props.status === "db") {
		setannotloc({ind_id:props.annot.ind_id, x:MillisToShortDate(props.annot.x), y:props.annot.y, atype:props.annot.atype, text:props.annot.text, id:props.annot.id, growth:(props.annot.growth == null ? "" : props.annot.growth), sortfield:(props.annot.sortfield == null ? "" : props.annot.sortfield), color:props.annot.color, delta:props.annot.delta})
		}
		  }, [props.annot]);

	  
	async function InsertAnnot (annot) {
		  try {
			  const result = await axios.post(`/insertannot`, 
												JSON.stringify(annot),
												{withCredentials: true,
									   headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
										}});
				return result.data.id
		  } catch (err) {
			  console.log(err)
			}
		
	}		

	async function UpdateAnnot (annot) {
		  try {
			  const result = await axios.put(`/updateannot/${annot.id}`, 
												JSON.stringify(annot),
												{withCredentials: true,
									   headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
										}});
				//return result.data.id
		  } catch (err) {
			  console.log(err)
			}
		
	}	


    function handleDeleteAnnot
	  (event) {  
	  		  try {
			  const result = axios.put(`/updateannot/${annotloc.id}`, 
												JSON.stringify({...annotloc, deleted:1}),
												{withCredentials: true,
									   headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
										}});
			  } catch (err) {
				  console.log(err)
				}
			props.dispatch({type:'UPD_ANN', annot:{...annotloc, deleted:1}})
		event.preventDefault();
	  }
  
    function handleUpsertAnnot
	  (event) {  
		if (EUDateStringToMillis(annotloc.x)) {	
			if (props.status === "new") {
				const insertid = InsertAnnot({...annotloc, x:EUDateStringToMillis(annotloc.x)})
				props.dispatch({type:'INS_ANN', annot:{...annotloc, x:EUDateStringToMillis(annotloc.x), id:insertid}})
			} else {
				UpdateAnnot({...annotloc, x:EUDateStringToMillis(annotloc.x)})
				props.dispatch({type:'UPD_ANN', annot:{...annotloc, x:EUDateStringToMillis(annotloc.x)}})
			}
			event.preventDefault();
		} else {
			setinvaliddate(true)
			event.preventDefault();
			event.stopPropagation();
		}
	  }	  
	
	 const renderConditionalFields = ()=>{
      if(isLine){
        return (
				<React.Fragment>
					<Col xs={1}>
					<Form.Control id={"growth"} value={annotloc.growth} onChange={handleChangeal} className="annot" placeholder="%" size="sm"  />
					</Col>					
					<Col xs={1}>
					<Form.Control id={"sortfield"} value={annotloc.sortfield} onChange={handleChangeal} className="annot"  size="sm"  />
					</Col>					
					<Col xs={3}>
					<Form.Control id={"color"} value={annotloc.color} onChange={handleChangeal} className="annot" size="sm"  />
					</Col>	
				</React.Fragment>
		)
      } else{
        return (
				<React.Fragment>
					<Col xs={4}>
					<Form.Control id={"text"} value={annotloc.text} onChange={handleChangeal} className="annot" placeholder="Label Text or Line Info" size="sm"  />
					</Col>
					<Col xs={1}>
					<Form.Control id={"delta"} value={annotloc.delta} className="annot"  size="sm" readOnly  />
					</Col>
				</React.Fragment>
		)
      }
    }
	
	
	
	return (<tr><td className="annottd" >
				<Form onSubmit={handleUpsertAnnot} >
				<Form.Row>
				<Col >
					<Form.Control id={"x"} value={annotloc.x} onChange={handleChangeal} className="annot" placeholder="x" size="sm" isInvalid={invaliddate} />
					</Col>
					<Col>
					<Form.Control id={"y"} value={annotloc.y} onChange={handleChangeal} className="annot" placeholder="y" size="sm"  />
					</Col>
					<Col >
					<Form.Control  as="select" id={"atype"} value={annotloc.atype} onChange={handleChangeal} className="annot" size="sm" >
						<option value="Label">Label</option>
						<option value="Line">Line</option>
					</Form.Control>
					</Col>
					{renderConditionalFields()}
					<Col>
						<Button variant="light" size="sm" type="submit" className="annot" >
						<FontAwesomeIcon
							  icon={faSave} 
							/>
					  </Button>
					  
					  						<Button variant="light" size="sm" className="annot" onClick={handleDeleteAnnot}>
						<FontAwesomeIcon
							  icon={faTimesCircle} 
							/>
					  </Button>
					  
					</Col>
				</Form.Row>
				</Form>	
			</td></tr>
	
	
	
	
	
)
}

const Legend = (props) => {

	return(<tr style={{backgroundColor:props.series.color}}>
								        <td><Form><Form.Check type="checkbox" 
										                      checked={props.series.enabled}
															  onChange= {props.handleChangeCB} 
															  data-key={props.series.sid}
															  data-src= {props.series.osrc}
															  data-fct= {props.series.oft}
															  data-symbol= {props.series.symbol} /></Form></td>
								        <td><img src={props.series.source} className='legend-logo' /></td>
										<td className="legendtext"> {props.series.function}</td>
									  </tr>)			
}

export default Index;